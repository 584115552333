import dayjs from 'dayjs';
import { PostDetail } from 'types/PostDetail';

export function appendIndexToPosts(posts: PostDetail[]) {
  const postsWithoutDate = posts.filter((post) => !post.date);
  const postsWithDate = posts
    .filter((post) => post.date)
    .sort((a, b) => (dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1));

  const indexMap = new Map();

  let index = -1;

  [...postsWithDate, ...postsWithoutDate].forEach((post) => {
    indexMap.set(post.id, ++index);
  });

  return posts.map((post) => ({
    ...post,
    index: indexMap.get(post.id),
  }));
}
