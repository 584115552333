import { get, post } from 'api/client';
import config from 'config';
import { CancelToken } from 'axios';
import { ListPost } from 'types/PostDetail';
import { getExtendedTimeout } from 'utils/request';
import { PostsMeta } from 'types/Post';
import { OrderDirection } from 'app/types';

export type GetPostsParams = {
  dateFrom: string;
  dateTo: string;
  profiles: number[];
  profileGroups: number[];
  statuses: number[];
  labels: number[];
  assignedUsers: number[];
  pseudoTypes: string[];
  projects: number[];
  orderDirection?: OrderDirection;
  limit?: number;
  page?: number;
};

export type GetPostsMetaParams = {
  profiles: number[];
  profileGroups: number[];
  dateFrom: string;
  dateTo: string;
};

export type GetPostTextInfoParams = {
  text: string;
  pageType: number;
};

export const PostsApi = {
  posts(
    params: GetPostsParams,
    cancelToken?: CancelToken,
  ): Promise<ApiResponse<ListPost[]>> {
    return get('/calendar/posts', {
      baseURL: config.API_SERVICE_ENDPOINT,
      params: {
        ...params,
        profiles: params.profiles.join() || undefined,
        profileGroups: params.profileGroups.join() || undefined,
        statuses: params.statuses.join() || undefined,
        labels: params.labels.join() || undefined,
        assignedUsers: params.assignedUsers.join() || undefined,
        pseudoTypes: params.pseudoTypes.join() || undefined,
        projects: params.projects.join() || undefined,
      },
      timeout: getExtendedTimeout(2),
      cancelToken,
    });
  },
  postsMeta: (params: GetPostsMetaParams): Promise<PostsMeta> => {
    return get('posts/metadata', {
      baseURL: config.API_SERVICE_ENDPOINT,
      timeout: getExtendedTimeout(),
      params: {
        ...params,
        profiles: params.profiles.join() || undefined,
        profileGroups: params.profileGroups.join() || undefined,
      },
    });
  },
  textInfo: ({
    text,
    pageType,
  }: GetPostTextInfoParams): Promise<{
    textLength: number;
    hashtags: number;
    mentions: number;
  }> => {
    return post(
      'posts/textInfo',
      { text, pageType },
      { baseURL: config.API_SERVICE_ENDPOINT, timeout: getExtendedTimeout() },
    );
  },
};
