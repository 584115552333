import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons/faArrowDownToLine';
import { Icon } from '@kontentino/ui';
import { ActionContext, createAction } from 'actions';
import { Comment } from 'app/modules/comments/types';
import i18n from 'app/utils/i18n';
import config from 'config';
import { UserPermission } from 'constants/userPermission';
import { PostDetail } from 'types/PostDetail';
import { createSearchFromParams, openBlank } from 'utils/url';

export const downloadCommentAttachments = (context: ActionContext) =>
  createAction(
    {
      id: 'download-comment-attachments',
      label: i18n.t('downloadAllAttachments'),
      icon: <Icon icon={faArrowDownToLine} />,
      scopes: UserPermission.POST_MASS_CHANGE_POST_STATUS,
      perform: ({
        id,
        postId,
      }: {
        id: Comment['id'];
        postId: PostDetail['id'];
      }) => {
        const searchParams = createSearchFromParams({
          comment_id: id,
          jwt: context.appStore.authToken,
        });

        openBlank(
          `${config.API_SERVICE_ENDPOINT}/posts/${postId}/downloadCommentAttachments${searchParams}`,
        );
      },
    },
    context,
  );
