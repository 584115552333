import { faArrowTurnDownRight } from '@fortawesome/pro-regular-svg-icons/faArrowTurnDownRight';
import { Icon } from '@kontentino/ui';
import { ActionContext, createAction } from 'actions';
import i18n from 'app/utils/i18n';
import { modalRoutes } from 'constants/routes';
import { UserPermission } from 'constants/userPermission';
import { PostDetail } from 'types/PostDetail';

export const movePost = (context: ActionContext) =>
  createAction(
    {
      id: 'move-post',
      label: i18n.t('move'),
      icon: <Icon icon={faArrowTurnDownRight} />,
      scopes: UserPermission.POST_MOVE,
      perform: ({ id }: { id: PostDetail['id'] }) => {
        context.modalHistory.push({
          hash: modalRoutes.MOVE_POSTS,
          search: `?posts=${id}`,
        });
      },
    },
    context,
  );
