import Pages from './pages';
import AdsPlanner from './adsPlanner';
import OldApp from './oldApp';
import Insights from './insights';

export const Module = {
  OldApp,
  Pages,
  AdsPlanner,
  Insights,
};

const config = {
  APP_VERSION: process.env.REACT_APP_VERSION as string,
  API_SERVICE_ENDPOINT_GRAPHQL: process.env
    .REACT_APP_API_SERVICE_ENDPOINT_GRAPHQL as string,
  API_SERVICE_ENDPOINT: process.env.REACT_APP_API_SERVICE_ENDPOINT as string,
  PUBLIC_API_SERVICE_ENDPOINT: process.env
    .REACT_APP_PUBLIC_API_SERVICE_ENDPOINT as string,
  AJAX_SERVICE_ENDPOINT:
    process.env.REACT_APP_AJAX_SERVICE_ENDPOINT || window.location.origin,
  MEDIA_UPLOAD_SERVICE_ENDPOINT:
    process.env.REACT_APP_MEDIA_UPLOAD_SERVICE_ENDPOINT,
  MENTIONS_SERVICE_ENDPOINT: process.env
    .REACT_APP_MENTIONS_SERVICE_ENDPOINT as string,
  LINK_SHORTENER_SERVICE_ENDPOINT: process.env
    .REACT_APP_LINK_SHORTENER_SERVICE_ENDPOINT as string,
  BRAND_HUB_SERVICE_ENDPOINT: process.env.REACT_APP_BRAND_HUB_SERVICE_ENDPOINT,
  INTERCOM_APP_ID: process.env.REACT_APP_INTERCOM_APP_ID,
  ANALYTICS_ENDPOINT: process.env.REACT_APP_ANALYTICS_ENDPOINT as string,
  AI_API_ENDPOINT: process.env.REACT_APP_AI_API_ENDPOINT as string,
  TEMP_ANALYTICS_FACEBOOK_TOKEN:
    process.env.REACT_APP_TEMP_ANALYTICS_FACEBOOK_TOKEN,
  REDUX_LOGGER_ON: process.env.REACT_APP_REDUX_LOGGER_ON,
  REQUEST_LOGGER_ON: process.env.REACT_APP_REQUEST_LOGGER_ON,
  RESPONSE_LOGGER_ON: process.env.REACT_APP_RESPONSE_LOGGER_ON,
  ENGAGE_API_URL: process.env.REACT_APP_ENGAGE_API_URL,
  FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID,
  FACEBOOK_SDK_VERSION: 'v3.2',
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  USERPILOT_APP_ID: process.env.REACT_APP_USERPILOT_APP_ID ?? '',
  BUGPILOT_ID: process.env.REACT_APP_BUGPILOT_ACCOUNT_ID,
  GTM_ID: process.env.REACT_APP_GTM_ID,
  STATIC_URL: process.env.REACT_APP_STATIC_URL,
  KONTENTINO_DEV_URL: process.env.REACT_APP_KONTENTINO_DEV_URL,
  SCREENSHOT_SERVICE: process.env.REACT_APP_SCREENSHOT_SERVICE,
  REACT_QUERY_DEV_TOOLS_ENABLED:
    process.env.REACT_APP_REACT_QUERY_DEV_ENABLED === 'true',
  KONTENTINO_HOMEPAGE_URL: 'https://www.kontentino.com',
  ALL_USER_PERMISSIONS_ENABLED:
    process.env.REACT_APP_ALL_USER_PERMISSIONS_ENABLED === 'true',
  NOTIFICATIONS_API_ENDPOINT: process.env
    .REACT_APP_NOTIFICATIONS_API_ENDPOINT as string,
  USERPILOT_DEBUG_ON: process.env.REACT_APP_USERPILOT_DEBUG_ON === 'true',
  PUBLIC_URL: process.env.PUBLIC_URL,
  MODE: process.env.REACT_APP_MODE as 'development' | 'production',
  ROUTER_BASE_URL: process.env.REACT_APP_BASE_ROUTE_URL ?? '',
  AWS_UPLOAD_BASE_URL: process.env.REACT_APP_AWS_UPLOAD_BASE_URL ?? '',
};

export default config;
