import { faEdit } from '@fortawesome/pro-regular-svg-icons/faEdit';
import { Icon } from '@kontentino/ui';
import { ActionContext, createAction } from 'actions';
import i18n from 'app/utils/i18n';
import { modalRoutes } from 'constants/routes';
import { UserPermission } from 'constants/userPermission';
import { PostDetail } from 'types/PostDetail';
import { createSearchFromParams } from 'utils/url';

export const editPost = (context: ActionContext) =>
  createAction(
    {
      id: 'edit-post',
      label: i18n.t('edit'),
      icon: <Icon icon={faEdit} />,
      scopes: UserPermission.POST_EDIT,
      perform: ({ id }: { id: PostDetail['id'] }) => {
        context.modalHistory.push({
          hash: modalRoutes.POST_DETAIL,
          search: createSearchFromParams({
            id,
            compose: true,
            isPostCreationDone: true,
          }),
        });
      },
    },
    context,
  );
