import {
  ADS_MANAGEMENT,
  NEW_ANALYTICS,
} from '@kontentino/kontentino-constants/Features';
import routes from 'constants/routes';
import { UserPermission } from 'constants/userPermission';
import { User } from 'types/User';
import { isDevelopmentMode } from 'utils/helpers';
import OldApp from 'config/oldApp';
import i18n from 'app/utils/i18n';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';
import { faChartPieSimple } from '@fortawesome/pro-regular-svg-icons/faChartPieSimple';
import { faChartSimple } from '@fortawesome/pro-regular-svg-icons/faChartSimple';
import { faChartTreeMap } from '@fortawesome/pro-regular-svg-icons/faChartTreeMap';
import { faCircleDollar } from '@fortawesome/pro-regular-svg-icons/faCircleDollar';
import { faFlag } from '@fortawesome/pro-regular-svg-icons/faFlag';
import { faFolderImage } from '@fortawesome/pro-regular-svg-icons/faFolderImage';
import { faGrid2 } from '@fortawesome/pro-regular-svg-icons/faGrid2';
import { faImagePolaroidUser } from '@fortawesome/pro-regular-svg-icons/faImagePolaroidUser';
import { faMicrochipAi } from '@fortawesome/pro-regular-svg-icons/faMicrochipAi';
import { faObjectsColumn } from '@fortawesome/pro-regular-svg-icons/faObjectsColumn';
import { faPaintbrushPencil } from '@fortawesome/pro-regular-svg-icons/faPaintbrushPencil';
import { faTable } from '@fortawesome/pro-solid-svg-icons/faTable';
import { isViewRestrictedForPageType } from 'app/modules/insights/utils/isViewRestrictedForPageType';
import { isIncludedInInsightsLiteExperiment } from 'app/modules/insightsLite/utils/isIncludedInInsightsLiteExperiment';
import { Badge } from '@kontentino/ui';
import React, { ReactNode } from 'react';
import { AccountStore } from 'modules/account/accountReducer';

export enum NavigationItemKey {
  Dashboard = 'dashboard',
  Posts = 'posts',
  PostsCalendar = 'postsCalendar',
  PostsList = 'postsList',
  PostsGrid = 'postsGrid',
  PostsBoard = 'postsBoard',
  AdsPlanner = 'adsplanner',
  Albums = 'albums',
  Insights = 'insights',
  InsightsPost = 'insightsPost',
  InsightsPage = 'insightsPage',
  InsightsCompletion = 'insightsCompletion',
  InsightsLite = 'insights-lite',
  AIContent = 'ai-content',
  BrandHub = 'brand-hub',
}

export type NavigationItem = {
  label: ReactNode;
  url?: string;
  icon?: IconDefinition;
  key: NavigationItemKey;
  isRequestable?: boolean;
  routerUrl?: string;
  featureTitle?: string;
  permissionsScope?: UserPermission | UserPermission[];
  onClick?: () => void;
  items?: NavigationItem[];
  isPageSubmenu?: boolean;
  isDisabled?: boolean;
};

type GetNavigationItems = {
  account: AccountStore;
  user: User;
  insightSelectedPageType?: number;
};

export const getNavigationItems = ({
  account,
  user,
  insightSelectedPageType,
}: GetNavigationItems): NavigationItem[] => {
  const featuresPermissions = account.featuresPermissions;

  const adsPlanner = featuresPermissions[ADS_MANAGEMENT];
  const newAnalytics = featuresPermissions[NEW_ANALYTICS];

  return [
    {
      label: i18n.t('navigation.dashboard'),
      routerUrl: routes.DASHBOARD,
      key: NavigationItemKey.Dashboard,
      icon: faObjectsColumn,
      permissionsScope: [
        UserPermission.ACCESS_DASHBOARD,
        UserPermission.ACCESS_MY_TASKS,
      ],
    },
    {
      label: i18n.t('navigation.views.default'),
      url: OldApp.URL.POSTS,
      routerUrl:
        isDevelopmentMode() || user.features_toggle.new_calendar
          ? routes.POSTS
          : undefined,
      key: NavigationItemKey.Posts,
      icon: faImagePolaroidUser,
      permissionsScope: UserPermission.ACCESS_CALENDAR,
      items: [
        {
          label: i18n.t('navigation.views.calendar'),
          url: OldApp.URL.POSTS,
          routerUrl:
            isDevelopmentMode() || user.features_toggle.new_calendar
              ? routes.POSTS_CALENDAR
              : undefined,
          key: NavigationItemKey.PostsCalendar,
          icon: faCalendar,

          permissionsScope: UserPermission.ACCESS_CALENDAR,
        },
        {
          label: i18n.t('navigation.views.list'),
          url: OldApp.URL.POSTS,
          routerUrl:
            isDevelopmentMode() || user.features_toggle.new_calendar
              ? routes.POSTS_LIST
              : undefined,
          key: NavigationItemKey.PostsList,
          icon: faTable,

          permissionsScope: UserPermission.ACCESS_CALENDAR,
        },
        {
          label: i18n.t('navigation.views.grid'),
          url: OldApp.URL.POSTS,
          routerUrl:
            isDevelopmentMode() || user.features_toggle.new_calendar
              ? routes.POSTS_GRID
              : undefined,
          key: NavigationItemKey.PostsGrid,
          icon: faGrid2,

          permissionsScope: UserPermission.ACCESS_CALENDAR,
        },
        {
          label: i18n.t('navigation.views.board'),
          url: OldApp.URL.POSTS,
          routerUrl:
            isDevelopmentMode() || user.features_toggle.new_calendar
              ? routes.POSTS_KANBAN
              : undefined,
          key: NavigationItemKey.PostsBoard,
          icon: faChartTreeMap,
          permissionsScope: UserPermission.ACCESS_CALENDAR,
        },
      ],
    },
    {
      label: i18n.t('navigation.adsPlanner.default'),
      routerUrl: routes.ADS_PLANNER,
      key: NavigationItemKey.AdsPlanner,
      icon: faCircleDollar,
      featureTitle: adsPlanner?.title,
      isRequestable: !adsPlanner?.hasAccess,
      permissionsScope: UserPermission.ACCESS_ADS_PLANNER,
    },
    {
      label: i18n.t('navigation.mediaLibrary.title'),
      // url: OldApp.URL.MEDIA_LIBRARY,
      routerUrl: routes.MEDIA_LIBRARY,
      key: NavigationItemKey.Albums,
      icon: faFolderImage,
      permissionsScope: UserPermission.ACCESS_MEDIA_LIBRARY,
    },
    {
      label: (
        <div className="tw-flex tw-items-center tw-gap-2">
          {i18n.t('navigation.analytics.default')}
          <Badge variant="gray">{i18n.t('lite')}</Badge>
        </div>
      ),
      routerUrl: routes.INSIGHTS_LITE,
      key: NavigationItemKey.InsightsLite,
      icon: faChartSimple,
      permissionsScope: UserPermission.ACCESS_CALENDAR,
    },
    {
      label: i18n.t('navigation.analytics.default'),
      routerUrl: isDevelopmentMode()
        ? routes.INSIGHTS
        : !user.features_toggle.new_analytics
        ? undefined
        : routes.INSIGHTS,
      url: OldApp.URL.ANALYTICS,
      key: NavigationItemKey.Insights,
      icon: faChartPieSimple,
      isRequestable: !newAnalytics?.hasAccess,
      featureTitle: newAnalytics?.title,
      permissionsScope: UserPermission.ACCESS_ANALYTICS,
      items: [
        {
          label: i18n.t('navigation.analytics.post'),
          routerUrl: isDevelopmentMode()
            ? routes.INSIGHTS_POSTS_PERFORMANCE
            : !user.features_toggle.new_analytics
            ? undefined
            : routes.INSIGHTS_POSTS_PERFORMANCE,
          key: NavigationItemKey.InsightsPost,
          url: OldApp.URL.ANALYTICS,
          icon: faGrid2,
          isRequestable: !newAnalytics?.hasAccess,
          featureTitle: newAnalytics?.title,
          permissionsScope: UserPermission.ACCESS_ANALYTICS,
          isDisabled: insightSelectedPageType
            ? isViewRestrictedForPageType(
                routes.INSIGHTS_POSTS_PERFORMANCE,
                insightSelectedPageType,
              )
            : false,
        },
        {
          label: i18n.t('navigation.analytics.page'),
          routerUrl: isDevelopmentMode()
            ? routes.INSIGHTS_PAGE_PERFORMANCE
            : !user.features_toggle.new_analytics
            ? undefined
            : routes.INSIGHTS_PAGE_PERFORMANCE,
          url: OldApp.URL.ANALYTICS,
          key: NavigationItemKey.InsightsPage,
          icon: faFlag,
          isRequestable: !newAnalytics?.hasAccess,
          featureTitle: newAnalytics?.title,
          permissionsScope: UserPermission.ACCESS_ANALYTICS,
          isDisabled: insightSelectedPageType
            ? isViewRestrictedForPageType(
                routes.INSIGHTS_PAGE_PERFORMANCE,
                insightSelectedPageType,
              )
            : false,
        },
        {
          label: i18n.t('navigation.analytics.competition'),
          routerUrl: isDevelopmentMode()
            ? routes.INSIGHTS_COMPETITION_ANALYSIS
            : !user.features_toggle.new_analytics
            ? undefined
            : routes.INSIGHTS_COMPETITION_ANALYSIS,
          url: OldApp.URL.ANALYTICS,
          key: NavigationItemKey.InsightsCompletion,
          icon: faChartPieSimple,
          isRequestable: !newAnalytics?.hasAccess,
          featureTitle: newAnalytics?.title,
          permissionsScope: UserPermission.ACCESS_ANALYTICS,
          isDisabled: insightSelectedPageType
            ? isViewRestrictedForPageType(
                routes.INSIGHTS_COMPETITION_ANALYSIS,
                insightSelectedPageType,
              )
            : false,
        },
      ],
    },
    {
      label: i18n.t('navigation.aiContent'),
      routerUrl: routes.AI_CONTENT,
      key: NavigationItemKey.AIContent,
      icon: faMicrochipAi,
      permissionsScope: UserPermission.POST_CREATE,
    },
    {
      label: i18n.t('navigation.brandHub'),
      routerUrl: routes.BRAND_HUB,
      key: NavigationItemKey.BrandHub,
      icon: faPaintbrushPencil,
      permissionsScope: UserPermission.POST_CREATE,
    },
  ].filter((item) => {
    return !(
      item.key === NavigationItemKey.InsightsLite &&
      !isIncludedInInsightsLiteExperiment({ account, user })
    );
  });
};
