import React, { FC } from 'react';
import LabelsView from 'components/shared/detail/LabelsView';
import AccordionWidgetIcon from 'app/modules/posts/components/accordionWidget/AccordionWidgetIcon';
import AccordionWidget from 'app/modules/posts/components/AccordionWidget';
import AccordionWidgetTitle from 'app/modules/posts/components/accordionWidget/AccordionWidgetTitle';
import { PostDetail } from 'types/PostDetail';
import LabelTag from 'components/shared/LabelTag';
import { Tag } from '@kontentino/ui';

type Props = {
  labels: PostDetail['tags'];
  isLoading: boolean;
  isError: boolean;
  accordionProps?: Partial<React.ComponentProps<typeof AccordionWidget>>;
};

const Labels: FC<Props> = ({ labels, isLoading, isError, accordionProps }) => {
  const visibleTags = labels.slice(0, 2);
  const truncatedTagsCount = labels.length - visibleTags.length;

  return (
    <AccordionWidget
      data-cy="post-preview-labels"
      icon={<AccordionWidgetIcon variant="labels" />}
      title={<AccordionWidgetTitle variant="labels" />}
      titleSuffix={(isOpen) =>
        visibleTags.length > 0 &&
        !isOpen && (
          <div className="tw-flex tw-items-center tw-justify-end tw-gap-1">
            {visibleTags.map((tag) => (
              <LabelTag
                key={tag.id}
                size="small"
                className="tw-max-w-[100px]"
                name={tag.name}
                color={tag.color}
              />
            ))}
            {truncatedTagsCount > 0 && (
              <Tag size="small" className="tw-whitespace-nowrap">
                + {truncatedTagsCount}
              </Tag>
            )}
          </div>
        )
      }
      {...accordionProps}
    >
      <LabelsView labels={labels} isLoading={isLoading} isError={isError} />
    </AccordionWidget>
  );
};

export default Labels;
