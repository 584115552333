import useSetState from 'utils/hooks/useSetState';

import { BrandProfile } from 'app/modules/brandHub/types';

type FilterState = {
  profileId: string | undefined;
  search: string;
};

export function useBrandHubFilterState() {
  const [filterState, setFilterState] = useSetState<FilterState>({
    profileId: undefined,
    search: '',
  });

  function onProfileSelect(profile: FilterState['profileId']) {
    setFilterState({ profileId: profile });
  }

  function preselectProfile(availableProfiles: BrandProfile[]) {
    setFilterState((state) => {
      if (availableProfiles.length === 0) {
        return { ...state, profileId: undefined };
      }

      if (
        typeof state.profileId === 'string' &&
        availableProfiles.map((profile) => profile.id).includes(state.profileId)
      ) {
        return state;
      }

      const firstProfile = availableProfiles[0];

      return {
        ...state,
        profileId: firstProfile?.id,
      };
    });
  }

  function onSearchChange(search: string) {
    setFilterState({
      search,
    });
  }

  return {
    filterState,
    filterActions: {
      onProfileSelect,
      onSearchChange,
      preselectProfile,
    },
  };
}
