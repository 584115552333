import { Toggle } from '@kontentino/ui';
import React, { FC } from 'react';
import { useDemoContentToggle } from 'app/modules/posts/hooks/useDemoContentToggle';
import { useTranslation } from 'react-i18next';
import { usePostsContainer } from 'components/posts/context';

const DemoContentToggle: FC = () => {
  const { changeFilter } = usePostsContainer();
  const { toggle, isEnabled } = useDemoContentToggle({
    onToggle: ({ enabled, profiles }) => {
      const profileToSelect = profiles.find((profile) =>
        enabled ? profile.isDemo : !profile.isDemo,
      );

      changeFilter({
        key: 'selectedProfiles',
        value: profileToSelect ? [profileToSelect.id] : [],
      });
    },
  });
  const { t } = useTranslation();

  return (
    <div
      data-name="posts-profile_selector-demo-content-toggle"
      className="tw-hidden tw-select-none tw-items-center tw-rounded-md tw-bg-purple-10 tw-px-2 tw-py-[6px] md:tw-flex"
    >
      <Toggle
        checked={isEnabled}
        onChange={() => toggle.mutate({ enabled: !isEnabled })}
      />
      <span className="tw-ml-2 tw-text-sm  tw-font-medium">
        {t('showDemoContent')}
      </span>
    </div>
  );
};

export default DemoContentToggle;
