import React, { FC, useEffect } from 'react';
import InsightsPageSelector from 'app/modules/insights/components/InsightsPageSelector';
import { useLocation } from 'react-router-dom';
import LayoutTopBar from 'app/components/layout/LayoutTopBar';
import { Button, DropdownMenu, Icon } from '@kontentino/ui';
import Skeleton from 'react-loading-skeleton';
import { useInsightsContainer } from 'components/insights/context';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faShareNodes } from '@fortawesome/pro-regular-svg-icons/faShareNodes';
import UserPermissionGate from 'components/UserPermissionGate';
import DatesPickers from 'components/insights/filterBar/DatesPickers';
import { usePostsPerformanceState } from '../modules/postsPerformance/hooks/usePostsPerformanceState';
import { animated, useTransition } from '@react-spring/web';
import MetricHeader from '../modules/postsPerformance/components/MetricHeader';
import routes from 'constants/routes';
import SyncProfileInsightsButton from './SyncProfileInsightsButton';
import { useCompetitionAnalysis } from '../modules/competitionAnalysis/hooks/useCompetitionAnalysis';
import PostsPerformanceFilterBar from '../modules/postsPerformance/modules/filterBar';
import ExportAsDropdown from '../modules/competitionAnalysis/components/ExportAsDropdown';
import DateUtils from 'app/utils/date';
import { useTranslation } from 'react-i18next';
import { useAppLayoutState } from 'components/appLayout/appLayoutState';
import { ActionDropdownMenuItem } from 'app/components/actions/ActionDropdownMenuItem';
import { useActions } from 'app/hooks/useActions';
import PagePerformanceExportsDropdown from 'app/modules/insights/modules/pagePerformance/components/PagePerformanceExportsDropdown';

const InsightsLayoutTopBar: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { setInsightSelectedPageType } = useAppLayoutState();
  const { metricHeader } = usePostsPerformanceState();
  const competitionAnalysisState = useCompetitionAnalysis();
  const {
    arePagesLoading,
    pages,
    activeState,
    filterState,
    changeSelectedProfiles,
  } = useInsightsContainer();

  useEffect(() => {
    let selectedPage = undefined;
    if (filterState.selectedProfiles[0]) {
      const selectedPageId = filterState.selectedProfiles[0];
      selectedPage = pages.find((page) => page._id === selectedPageId);
    }
    setInsightSelectedPageType(selectedPage?._type);
  }, [filterState.selectedProfiles, setInsightSelectedPageType, pages]);

  const transitions = useTransition(metricHeader, {
    from: { translateX: '-100%' },
    enter: { translateX: '0%' },
  });

  const { profileActions } = useActions('add-new-dropdown');

  return (
    <LayoutTopBar shadow className="tw-z-20 tw-justify-between">
      <div className="tw-flex tw-gap-x-2">
        <>
          {arePagesLoading ? (
            <div className="tw-mr-auto tw-flex tw-flex-1 tw-items-center tw-justify-start">
              <Skeleton width={200} height={36} />
            </div>
          ) : (
            <div className="tw-mr-auto tw-flex tw-flex-1 tw-items-center tw-justify-start">
              {!location.pathname.includes(routes.INSIGHTS_PAGE_PERFORMANCE) &&
                transitions(
                  (styles, metric) =>
                    metric && (
                      <animated.div style={styles}>
                        <MetricHeader metric={metric} />
                      </animated.div>
                    ),
                )}
              <InsightsPageSelector
                pages={pages}
                selectedPageId={filterState.selectedProfiles[0]}
                onSelectPage={changeSelectedProfiles}
              />
            </div>
          )}
        </>
        <DatesPickers />
      </div>
      <div className="tw-flex tw-gap-x-2">
        {activeState.page && (
          <div>
            {location.pathname.includes(routes.INSIGHTS_POSTS_PERFORMANCE) && (
              <PostsPerformanceFilterBar />
            )}
            {location.pathname.includes(
              routes.INSIGHTS_COMPETITION_ANALYSIS,
            ) && (
              <div className="tw-flex tw-gap-2">
                <ExportAsDropdown
                  startDate={DateUtils.toDateString(filterState.startDate)}
                  endDate={DateUtils.toDateString(filterState.endDate)}
                  page={activeState.page}
                  showReactions={competitionAnalysisState.showReactions}
                  selectedPages={competitionAnalysisState.selectedPages}
                />
                <SyncProfileInsightsButton page={activeState.page} />
              </div>
            )}
            {location.pathname.includes(routes.INSIGHTS_PAGE_PERFORMANCE) && (
              <div className="tw-flex tw-gap-2">
                <PagePerformanceExportsDropdown page={activeState.page} />
                <SyncProfileInsightsButton page={activeState.page} />
              </div>
            )}
          </div>
        )}
        <div className="tw-ml-auto tw-flex tw-flex-1 tw-items-center tw-justify-end">
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <Button
                className="tw-h-[36px] tw-capitalize"
                variant="primary"
                data-name="insights-add-new"
                data-cy="insights-add-new-dropdown"
                iconAfter={<Icon icon={faChevronDown} />}
              >
                {t('new')}
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              <UserPermissionGate scopes={profileActions.add.scopes}>
                <ActionDropdownMenuItem
                  data-name="insights-add-new-profile"
                  action={profileActions.add}
                >
                  <Icon icon={faShareNodes} />
                  {t('profile')}
                </ActionDropdownMenuItem>
              </UserPermissionGate>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>
      </div>
    </LayoutTopBar>
  );
};

export default InsightsLayoutTopBar;
