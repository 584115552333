import { useCallback, useState } from 'react';
import mergeWith from 'lodash.mergewith';

type DeepPartial<T> = T extends object
  ? { [P in keyof T]?: DeepPartial<T[P]> }
  : T;

export type UseSetStateAction<T> = (
  patch: DeepPartial<T> | ((prevState: T) => DeepPartial<T>),
) => void;

const customMerge = <T extends object>(
  object: T,
  source: DeepPartial<T>,
): T => {
  return mergeWith({}, object, source, (objValue, srcValue) => {
    if (Array.isArray(srcValue)) {
      return srcValue;
    }
  });
};

const useSetState = <T extends object>(
  initialState: T | (() => T) = {} as T,
): [T, UseSetStateAction<T>] => {
  const [state, set] = useState<T>(initialState);

  const setState: UseSetStateAction<T> = useCallback((patch) => {
    set((prevState) => {
      const newPatch = patch instanceof Function ? patch(prevState) : patch;
      return customMerge(prevState, newPatch);
    });
  }, []);

  return [state, setState];
};

export default useSetState;
