import { v4 } from 'uuid';
import { useState } from 'react';

export type GenerateCreativeConfig = {
  id: string;
  goal: string;
  brandProfileId: string;
  pageType: number;
  creativeLength?: string;
};

export function useBrandHubGenerateCreatives() {
  const [generateCreatives, setGenerateCreatives] = useState<
    GenerateCreativeConfig[]
  >([]);

  function addConfig(
    newConfig:
      | Omit<GenerateCreativeConfig, 'id'>
      | Omit<GenerateCreativeConfig, 'id'>[],
  ) {
    const newConfigs = Array.isArray(newConfig) ? newConfig : [newConfig];

    setGenerateCreatives((prevState) => [
      ...newConfigs.map((params) => ({
        ...params,
        id: v4(),
      })),
      ...prevState,
    ]);
  }

  function removeConfig(id: string) {
    setGenerateCreatives((prevState) =>
      prevState.filter((creative) => creative.id !== id),
    );
  }

  return {
    generateCreatives,
    generateCreativesActions: {
      addConfig,
      removeConfig,
    },
  };
}
